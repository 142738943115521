import { useTranscriptContext } from './useTranscriptContext.tsx';
import { useTranscriptSignals } from './useTranscriptSignals.tsx';

const DELAY_FOR_REENABLING_AUTOSCROLL_MS = 250;

type UseAutoEnableAutoScrollReturn = () => void;

export const useAutoEnableAutoScroll = (): UseAutoEnableAutoScrollReturn => {
  const { isAutoScrollEnabled } = useTranscriptSignals();
  const { hasAutoScrollControl } = useTranscriptContext();

  const autoEnableAutoScroll = () => {
    if (!hasAutoScrollControl) {
      return;
    }
    // We need to use a setTimeout here because if we didn't, auto-scroll would get
    // enabled before the current-word updates to the one at the new time , and
    // we'd scroll down to the previous current-word. 250ms seems to safely avoid
    // that. Plus the delay makes the visible change in the auto-scroll switch
    // control more noticeable.
    setTimeout(() => {
      isAutoScrollEnabled.value = true;
    }, DELAY_FOR_REENABLING_AUTOSCROLL_MS);
  };

  return autoEnableAutoScroll;
};
