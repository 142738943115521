import { ComponentChildren, createContext, h, JSX } from 'preact';
import { useContext, useEffect, useState } from 'preact/hooks';
import { useWistiaPlayerContext } from './useWistiaPlayerContext.tsx';

type PlayerCaptionsLanguageContextType = {
  playerCaptionsLanguage: string | undefined;
};

const PlayerCaptionsLanguageContext = createContext<PlayerCaptionsLanguageContextType | null>(null);

export const PlayerCaptionsLanguageProvider = ({
  children,
}: {
  children: ComponentChildren;
}): JSX.Element => {
  const [playerCaptionsLanguage, setPlayerCaptionsLanguage] = useState<string | undefined>(
    undefined,
  );
  const { player } = useWistiaPlayerContext();

  useEffect(() => {
    // bind returns its own unbind function
    return player.bind('captionslanguagechange', (language: string) => {
      setPlayerCaptionsLanguage(language === '_off_' ? undefined : language);
    });
  }, [player]);

  const value = {
    playerCaptionsLanguage,
  };

  return (
    <PlayerCaptionsLanguageContext.Provider value={value}>
      {children}
    </PlayerCaptionsLanguageContext.Provider>
  );
};

export const usePlayerCaptionsLanguage = (): PlayerCaptionsLanguageContextType => {
  const context = useContext(PlayerCaptionsLanguageContext);
  if (context === null) {
    throw new Error(
      'usePlayerCaptionsLanguage must be used within a PlayerCaptionsLanguageProvider',
    );
  }
  return context;
};
