import { isNil } from '@wistia/type-guards';
import { PlayerState } from '../../../types/player-api-types.ts';
import { ScrollStrategy } from '../types.ts';

// Do autoscroll if the current word is _near_ the edge of the transcript document viewport
export const MARGIN_PX_AT_WHICH_TO_DO_AUTO_SCROLL = 20;
// Disable autoscroll if the current word is scrolled _outside of_ the transcript document viewport
export const MARGIN_PX_AT_WHICH_TO_DISABLE_AUTO_SCROLL = -20;

export const isChildWithinParentScrollViewport = ({
  child,
  marginTop = 0,
  marginBottom = 0,
  parent,
}: {
  child: Element | null | undefined;
  marginBottom?: number;
  marginTop?: number;
  parent: HTMLElement | null | undefined;
}): boolean => {
  return (
    child instanceof HTMLElement &&
    parent instanceof HTMLElement &&
    child.offsetTop >= parent.scrollTop + marginTop &&
    child.offsetTop + child.clientHeight <= parent.scrollTop + parent.clientHeight - marginBottom
  );
};

export const isCurrentWordVisibleInScrollRegion = ({
  marginTop = 0,
  marginBottom = 0,
  transcriptScrollContainer,
  scrollStrategy,
  currentWordElement,
}: {
  currentWordElement: HTMLElement | null;
  marginBottom?: number;
  marginTop?: number;
  scrollStrategy: ScrollStrategy;
  transcriptScrollContainer?: HTMLElement | null;
}): boolean => {
  if (currentWordElement === null || transcriptScrollContainer === null) {
    return true;
  }

  const currentWordHeight = currentWordElement.clientHeight;

  if (scrollStrategy === 'within-embed') {
    return isChildWithinParentScrollViewport({
      child: currentWordElement,
      marginTop,
      marginBottom,
      parent: transcriptScrollContainer,
    });
  }

  const currentWordTop = currentWordElement.getBoundingClientRect().top;

  // scrollStrategy is 'whole-page', so check if within window viewport, with margin
  const result =
    currentWordTop >= marginTop &&
    currentWordTop + currentWordHeight <= window.innerHeight - marginBottom;

  return result;
};

export const maybeAutoScroll = ({
  behavior = 'auto',
  transcriptScrollContainer,
  scrollStrategy,
  autoScrollMarginTopPx,
  playerState,
  currentWordElement,
  isManuallyScrollingNow,
  isAutoScrollEnabled,
  onAutoScroll,
}: Pick<ScrollToOptions, 'behavior'> & {
  autoScrollMarginTopPx: number;
  currentWordElement: HTMLElement | null;
  isAutoScrollEnabled: boolean;
  isManuallyScrollingNow: boolean;
  onAutoScroll: () => void;
  playerState: PlayerState;
  scrollStrategy: ScrollStrategy;
  shouldNextAutoScrollBeInstant: boolean;
  transcriptScrollContainer: HTMLElement | null;
}): void => {
  // if scroll strategy is whole page, don't allow scroll if beforeplay
  if (scrollStrategy === 'whole-page' && playerState === 'beforeplay') {
    return;
  }

  if (isNil(currentWordElement) || isNil(transcriptScrollContainer)) {
    return;
  }

  // Prevent scrolljacking
  if (isManuallyScrollingNow) {
    return;
  }

  if (
    !isAutoScrollEnabled ||
    isCurrentWordVisibleInScrollRegion({
      marginTop: MARGIN_PX_AT_WHICH_TO_DO_AUTO_SCROLL + autoScrollMarginTopPx,
      marginBottom: MARGIN_PX_AT_WHICH_TO_DO_AUTO_SCROLL,
      scrollStrategy,
      transcriptScrollContainer,
      currentWordElement,
    })
  ) {
    return;
  }

  // isAutoScrollingNow.value = true;
  // shouldNextAutoScrollBeInstant.value = false;
  onAutoScroll();

  if (scrollStrategy === 'within-embed') {
    const heightToScrollTo = currentWordElement.offsetTop - MARGIN_PX_AT_WHICH_TO_DO_AUTO_SCROLL;

    // We use scrollTo on the transcriptScrollContainer element instead of calling
    // scrollIntoView on the word element, since the latter would scroll the
    // entire page if the transcript is not in the browser viewport. Scrolling the
    // transcript should be confined to scrolling within the transcript embed.
    transcriptScrollContainer.scrollTo({ top: heightToScrollTo, behavior });
  } else {
    currentWordElement.scrollIntoView({ behavior, block: 'center' });
  }
};

export const getScrollStrategy = ({
  transcriptScrollContainer,
}: {
  transcriptScrollContainer: HTMLElement | null;
}): ScrollStrategy => {
  if (!transcriptScrollContainer) {
    return 'within-embed';
  }
  return transcriptScrollContainer.scrollHeight > transcriptScrollContainer.clientHeight
    ? 'within-embed'
    : 'whole-page';
};
