import { h, JSX } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import { Switch } from '../../shared/components/Switch.tsx';
import { maybeAutoScroll } from '../utilities/autoScrollUtilities.ts';
import { useTranscriptContext } from '../hooks/useTranscriptContext.tsx';
import { HoverButtonyLabel } from './HoverButtons/HoverButtonyLabel.tsx';
import { useScrollStrategy } from '../hooks/useScrollStrategy.tsx';
import { useTranscriptSignals } from '../hooks/useTranscriptSignals.tsx';

export const AUTO_SCROLL_TOGGLE_STYLES = `
  .auto-scroll-switch {
    align-items: center;
    background-color: white;
    border-radius: 20px;
    border: 1px solid white;
    box-sizing: border-box;
    color: #62626c;
    cursor: pointer;
    display: flex;
    height: 18px;
    justify-content: flex-end;
    margin: 0;
    overflow: hidden;
    padding: 0;
    padding: 3px;
    position: relative;
    transition: background 175ms ease-out;
    vertical-align: middle;
    width: 42px;
  }

  .auto-scroll-switch:hover {
    opacity: 0.8;
  }

  .auto-scroll-switch:focus-visible {
    outline: 2px solid var(--focus-ring-color);
    outline-offset: 1px;
  }

  .auto-scroll-switch:has(> input:checked) {
    background-color: var(--accent-color);
    color: white;
  }

  .switch-knob {
    background-color: white;
    border-radius: 50%;
    display: block;
    height: 100%;
    transition: transform 175ms ease-out;
    aspect-ratio: 1;
    position: relative;
  }

  .switch-knob::before,
  .switch-knob::after {
    display: inline-block;
    font-size: 10px;
    line-height: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    word-break: normal;
  }

  .switch-knob::before {
    content: 'OFF';
    left: 13px;
  }

  .switch-knob::after {
    content: 'ON';
    right: 16px;
  }

  .switch-knob:not(:has(+ input:checked)) {
    transform: translateX(-24px);
    background-color: grey;
  }
`;

export const AutoScrollToggle = (): JSX.Element => {
  const {
    isAutoScrollEnabled,
    playerState,
    isAutoScrollingNow,
    shouldNextAutoScrollBeInstant,
    currentWordElement,
    isManuallyScrollingNow,
  } = useTranscriptSignals();
  const switchRef = useRef<HTMLButtonElement>(null);
  const { transcriptScrollContainer, autoScrollMarginTopPx } = useTranscriptContext();
  const { scrollStrategy } = useScrollStrategy();

  useEffect(() => {
    maybeAutoScroll({
      scrollStrategy,
      transcriptScrollContainer,
      autoScrollMarginTopPx,
      playerState: playerState.value,
      onAutoScroll: () => {
        isAutoScrollingNow.value = true;
        shouldNextAutoScrollBeInstant.value = false;
      },
      currentWordElement: currentWordElement.value,
      isAutoScrollEnabled: isAutoScrollEnabled.value,
      isManuallyScrollingNow: isManuallyScrollingNow.value,
      shouldNextAutoScrollBeInstant: shouldNextAutoScrollBeInstant.value,
    });
  }, [
    isAutoScrollEnabled.value,
    scrollStrategy,
    transcriptScrollContainer,
    autoScrollMarginTopPx,
    currentWordElement.value,
    isAutoScrollEnabled.value,
    isManuallyScrollingNow.value,
    shouldNextAutoScrollBeInstant.value,
  ]);

  return (
    <HoverButtonyLabel>
      Auto-scroll
      <Switch
        ref={switchRef}
        className="auto-scroll-switch"
        part="switch"
        onCheckedChange={(checked) => {
          isAutoScrollEnabled.value = checked;
        }}
        checked={isAutoScrollEnabled.value}
      />
    </HoverButtonyLabel>
  );
};
